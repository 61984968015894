<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2"></main>
</template>
<script>
export default {
  data() {
    var evennam = this.$route.params.venuename;
    evennam = evennam.replace(" ", "%20");
    var requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    fetch("https://ticketmasteraccount.com/api/google/" + evennam, requestOptions)
      .then(response => response.text())
      .then(result => {
        alert(`Total Capacity : ${result}`);
        window.location.href = "../admin/tickets";
      })
      .catch(error => console.log("error", error));
  }
};
</script>
